<template>
  <div class="about">
    <div v-if="screenWidth>975">
      <div class="head">
        <div class="logobox">
          <img src="../../assets/images/logo.png" alt />
        </div>
        <div class="navbox">
          <!-- <div class="navitem">控制台</div>
          <div class="navitem">我的项目</div>
          <div class="navitem">我的账单</div>
          <div class="navitem">账户设置</div>-->
          <div class="navitem" @click="toHome">首页</div>
          <div class="navitem navitem-on">关于我们</div>
          <div class="navitem" @click="toVideo">视频中心</div>
          <div class="navitem" @click="toPlatform">开放平台</div>
        </div>
      </div>
      <div class="nav-left">
        <!-- <div class="logo">logo</div> -->
        <div class="onenav">
          <div :class="navStatus ? 'navCancel' : 'navAction'" @click="switchNav(0)">安教</div>
          <div :class="!navStatus ? 'navCancel' : 'navAction'" @click="switchNav(6)">职教</div>
        </div>
        <div class="nav" v-show="!navStatus">
          <div class="nav-li" :class="active == 0?'nav-li-on':''" @click="scrollTo(0)">
            安教
            <div v-if="active == 0" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
          <div class="nav-li" :class="active == 1?'nav-li-on':''" @click="scrollTo(1)">
            实名
            <div v-if="active == 1" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
          <div class="nav-li" :class="active == 2?'nav-li-on':''" @click="scrollTo(2)">
            微课
            <div v-if="active == 2" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
          <div class="nav-li" :class="active == 3?'nav-li-on':''" @click="scrollTo(3)">
            培训
            <div v-if="active == 3" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
          <div class="nav-li" :class="active == 4?'nav-li-on':''" @click="scrollTo(4)">
            考核
            <div v-if="active == 4" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
          <div class="nav-li" :class="active == 5?'nav-li-on':''" @click="scrollTo(5)">
            溯源
            <div v-if="active == 5" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
          <!-- <div class="nav-li" :class="active == 0?'nav-li-on':''" @click="scrollTo(0)">安教</div>
          <div class="nav-li" :class="active == 1?'nav-li-on':''" @click="scrollTo(1)">实名</div>
          <div class="nav-li" :class="active == 2?'nav-li-on':''" @click="scrollTo(2)">微课</div>
          <div class="nav-li" :class="active == 3?'nav-li-on':''" @click="scrollTo(3)">培训</div>
          <div class="nav-li" :class="active == 4?'nav-li-on':''" @click="scrollTo(4)">考核</div>
          <div class="nav-li" :class="active == 5?'nav-li-on':''" @click="scrollTo(5)">溯源</div>-->
        </div>
        <div class="nav" v-show="navStatus">
          <div class="nav-li" :class="active == 6?'nav-li-on':''" @click="scrollTo(6)">
            大纲
            <div v-if="active == 6" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
          <div class="nav-li" :class="active == 7?'nav-li-on':''" @click="scrollTo(7)">
            内容
            <div v-if="active == 7" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
          <div class="nav-li" :class="active == 8?'nav-li-on':''" @click="scrollTo(8)">
            优势
            <div v-if="active == 8" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
          <div class="nav-li" :class="active == 9?'nav-li-on':''" @click="scrollTo(9)">
            课程<br>制作<br>过程
            <div v-if="active == 9" :style="{width:lineWidth + 'px'}" class="rihtLine"></div>
          </div>
        </div>
      </div>
      <div class="main-right">
        <div class="watermark"><img src="../../assets/images/watermark.png" alt=""></div>
        <div class="main-li main-li1">
          <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInRight"
            leave-active-class="animated zoomOut"
            :duration="3000"
          >
            <div class="imgbox" v-show="transitionFlag1">
              <img src="../../assets/images/about_anjiao.png" alt />
            </div>
          </transition>

          <div class="contentbox">
            <div class="infobox">
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="info-title" v-show="transitionFlag1f">在线教育 避免聚集</div>
              </transition>
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag1f"
                >传统建筑三级安全教育采用集中面授式培训，存在组织难、监督难、考核难、教材制作难、资料归档难等问题。建筑工地作业人员流动性高，会议\办公空间有限，频发的集中培训活动不利于卫生防护特别是传染性疫情的控制。 抖工安教采用离散式实名在线培训模式，在满足建筑工地三级安全教育的法律法规前提下，实现作业人员安全培训全程自动化。</div>
              </transition>

              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeIn"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="fontbox" v-show="transitionFlag1f">
                  <img src="../../assets/images/Safety2x.png" alt />
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="main-li main-li2">
          <div class="contentbox">
            <div class="infobox">
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="info-title" v-show="transitionFlag2f">自助实名 极速构建工地花名册</div>
              </transition>
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag2f"
                >抖工安教实名登记采用工人自主认证方式，通过抖工安教APP即可完成身份证识别和人像识别，全程“0”输入，可以快速的构建起工地实名制花名册。相比于手工登记或者是身份证刷卡机读卡登记需要专职工作人员的作业方式简便了许多。班组长以及项目管理人员也可以实时知晓每一个工人的实名登记情况，更好的管理班组人员变动情况。</div>
              </transition>
              <!-- fontbox -->
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeIn"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="fontbox" v-show="transitionFlag2f">
                  <img src="../../assets/images/Real-name2x.png" alt />
                </div>
              </transition>
            </div>
          </div>

          <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInRight"
            leave-active-class="animated zoomOut"
            :duration="3000"
          >
            <div class="content-imgbox" v-show="transitionFlag2">
              <div class="imgbox1">
                <img src="../../assets/images/shiming1.png" alt />
              </div>
              <div class="imgbox2">
                <img src="../../assets/images/shiming2.png" alt />
              </div>
            </div>
          </transition>
        </div>
        <div class="main-li main-li3">
          <div class="contentbox">
            <!-- info -->
            <div class="infobox">
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="info-title" v-show="transitionFlag3f">精品微课 好学好学</div>
              </transition>
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag3f"
                >抖工微课中心自主研发了超过500份工地安全培训微课教材，教材根据规范编制为2-3分钟的微课教材，每一份教材均配有视频、图文、考题、语音。将安全教育的知识点进行实地场景或者情景还原拍摄，让安全知识更易被工人理解和吸收，不识字或者视力\听力有障碍工人也能完成学习。</div>
              </transition>
              <!-- fontbox -->
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeIn"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="fontbox" v-show="transitionFlag3f">
                  <img src="../../assets/images/classical2x.png" alt />
                </div>
              </transition>
              <!-- <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag3f"
                >抖工教材中心，根据规范编制图文教材，针对知识点并结合当地安监要求编制考题，将视频、图文、考题三者融合程标准化教材。</div>
              </transition>-->
            </div>
          </div>
          <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInLeft"
            leave-active-class="animated zoomOut"
            :duration="3000"
          >
            <div class="imgbox" v-show="transitionFlag3">
              <img src="../../assets/images/about_weike.png" alt />
            </div>
          </transition>
        </div>
        <div class="main-li main-li4">
          <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInRight"
            leave-active-class="animated zoomOut"
            :duration="3000"
          >
            <div class="imgbox" v-show="transitionFlag4">
              <img src="../../assets/images/about_peixun.png" alt />
            </div>
          </transition>
          <div class="contentbox">
            <!-- info -->
            <div class="infobox">
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="info-title" v-show="transitionFlag4f">自定义培训任务 一键下发</div>
              </transition>
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag4f"
                >抖工安教平台完全覆盖三级安全教育的培训管理需求，轻松组织安全培训任务，通过APP下发到每一个工人手机中，工人可以像“上微信,看抖音”一样完成安全培训学习。 通过培训监控中心企业可以了解每一个项目每一个工人被安排了什么培训任务？任务内容是什么？都分配给了谁？谁学习了多少时间？学习成果怎么样？都可以通过系统得到量化的数据结果。</div>
              </transition>
              <!-- fontbox -->
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeIn"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="fontbox" v-show="transitionFlag4f">
                  <img src="../../assets/images/task2x.png" alt />
                </div>
              </transition>
              <!-- <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag4f"
                >抖工安教前端通过APP的形式,安装在每一个工人的手机中,直接将工人与管理后端连接,培训任务直接下发到工人手机中，工人可以像“上微信,看抖音”一样完成安全培训学习。</div>
              </transition>-->
            </div>
          </div>
        </div>
        <div class="main-li main-li5">
          <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInRight"
            leave-active-class="animated zoomOut"
            :duration="3000"
          >
            <div class="imgbox" v-show="transitionFlag5">
              <img src="../../assets/images/about_kaohe.png" alt />
            </div>
          </transition>
          <div class="contentbox">
            <!-- info -->
            <div class="infobox">
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="info-title" v-show="transitionFlag5f">千人千卷 严控安全意识</div>
              </transition>
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag5f"
                >抖工安教采用边培训边做题的方式进行考核，培训内容均为微课短视频以及带语音朗读的图文材料，每一节微课均有配套的考题，视力或听力无障碍的工人均可完成培训学习和考核。</div>
              </transition>
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag5f"
                >在完成培训任务的过程中，每完成一节微课（一般小于3分钟）的学习，都需要进行答题，并且题目具有系统语音朗读功能。</div>
              </transition>
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag5f"
                >工人答对题目后，自动进入下一节微课学习，如果回答错误，则需要重新学习本微课。工人完成培训的同时也就完成了安全意识考核，考核必须是满分才可完成培训任务。</div>
              </transition>
              <!-- fontbox -->
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeIn"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="fontbox" v-show="transitionFlag5">
                  <img src="../../assets/images/test2x.png" alt />
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="main-li main-li6">
          <div class="contentbox">
            <!-- info -->
            <div class="infobox">
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="info-title" v-show="transitionFlag6f">扫码溯源 有备无患</div>
              </transition>
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag6f"
                >抖工安教采用区块链技术，在遵循当前行业规范的前提下，构建了安全培训电子档案溯源系统，可以通过系统追溯工人的历史培训情况。有效杜绝瞒报、伪造培训记录的情况。</div>
              </transition>
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div
                  class="info-text"
                  v-show="transitionFlag6f"
                >抖工安教档案中心,可通过电子签名或扫描纸质签名资料的方式登记每一位工人的培训归档记录,形成可追溯的电子档案库。</div>
              </transition>
              <!-- fontbox -->
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeIn"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
                <div class="fontbox" v-show="transitionFlag6f">
                  <img src="../../assets/images/roots2x.png" alt />
                </div>
              </transition>
            </div>
          </div>
          <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInLeft"
            leave-active-class="animated zoomOut"
            :duration="3000"
          >
            <div class="imgbox" v-show="transitionFlag6">
              <img src="../../assets/images/about_suoyuan.png" alt />
            </div>
          </transition>
        </div>
        <div class="main-li main-li7">
          <div  class="outline">
            <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
            >
              <div class="list1" v-show="transitionFlag7">
                <div>
                  <div class="title">课程内容体系大纲</div>
                  <div class="content">1、课程内容体系大纲包括必修及选修两部分，其中必修课4学时，选修课20学时，共计24学时；<br>2、选修课共计课程30门，不同课程匹配不同学时，可自由选择搭配，总学时满足要求即可。</div>
                </div>
                <img src="../../assets/images/pic.png">
              </div>
            </transition>
            <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInRight"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
            <div class="list2" v-if="transitionFlag7f">
              <div class="left" >
                <div class="title">必修课</div>
                <span class="outlineBox">建筑安全法规<br>讲解</span>
                <span class="outlineBox">施工企业安全<br>与相关责任人<br>员的安全责任</span>
              </div>
              <div class="right" v-if="transitionFlag7f">
                <div class="title">选修课</div>
                <div class="content">
                  <span class="outlineBox">BIM技术分析<br>应用(三门)</span>
                  <span class="outlineBox">超高层建筑施工<br>安全措施</span>
                  <span class="outlineBox">地下工程施<br>工监测技术</span>
                  <span class="outlineBox">工程质量<br>安全手册</span>
                  <span class="outlineBox">建设工程安全<br>生产管理条例</span>
                  <span class="outlineBox">建设工程施工现场<br>消防安全技术规范</span>
                  <span class="outlineBox">建筑机械<br>安全管理</span>
                  <span class="outlineBox">建筑施工安全管理<br>及事故案例分析</span>
                  <span class="outlineBox">沥青路面新技术及<br>绿色施工技术</span>
                  <span class="outlineBox">绿色建筑<br>标准化图集</span>
                  <span class="outlineBox">模板支架、脚<br>手架安全使用</span>
                  <span class="outlineBox">深基坑工程<br>安全控制</span>
                  <span class="outlineBox">施工安全前期<br>准备工作培训</span>
                  <span class="outlineBox">市政工程典型安全<br>事故案例分析</span>
                  <span class="outlineBox">建筑工程施工现场特种作业<br>安全管理与法规解读</span>
                  <span class="outlineBox">绿色施工的组织<br>和实施(两门)</span>
                  <span class="outlineBox">装配式建筑<br>结构(两门)</span>
                  <span class="outlineBox">全面落实安全<br>生产主体责任</span>
                  <span class="outlineBox">BIM技术落<br>地应用介绍</span>
                  <span class="outlineBox">施工现场重大危险源<br>&nbsp;&nbsp;识别与控制及注意事项&nbsp;&nbsp;</span>
                  <span class="outlineBox">&nbsp;&nbsp;市政公用工程安全&nbsp;&nbsp;<br>生产标准规范</span>
                  <span class="outlineBox">&nbsp;&nbsp;污水处理厂&nbsp;&nbsp;<br>工艺管道工程</span>
                  <span class="outlineBox">隐患排查治理<br>体系建设培训</span>
                  <span class="outlineBox">智慧<br>工地</span>
                  <span class="outlineBox">住建部质量安全提升<br>行动专题讲座</span>
                  <span class="outlineBox">新安全<br>生产法</span>
                </div>
              </div>
            </div>
            </transition>
          </div>
        </div>
        <div class="main-li main-li8">
          <div class="content">
            <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInRight"
            leave-active-class="animated zoomOut"
            :duration="3000"
          >
            <div class="left" v-show="transitionFlag8">
              <div class="title">施工企业安全与相关责任人员的安全责任</div>
              <div class="line">第一章 工程建设安全概述</div>
              <div class="line2">第一节 工程建设安全概述</div>
              <div class="line2">第二节 工程建设安全概述</div>
              <div class="line2">第三节 工程建设安全概述</div>
              <div class="line">第二章 法律责任</div>
              <div class="line">第三章 施工企业的安全行政责任</div>
              <div class="line">第四章 相关责任人员行政责任清单</div>
              <div class="line2">第一节 主要负责人的安全生产行政责任清单</div>
              <div class="line2">第二节 项目经理现场责任清单及专职安全管理人员的安全生产行政责任清单</div>
              <div class="line2">第三节 直接负责的主管人员和其他负责人的行政责任</div>
              <div class="line2">第四节 注册执业人员</div>
              <div class="line">第五章 安全生产信用严惩</div>
              <div class="line">第六章 安全生产刑事责任</div>
              <img class="image" src="../../assets/images/eg.png" alt="">
            </div>
            </transition>
            <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
            <div class="right" v-show="transitionFlag8f">
              <div class="item mb">
                <div class="line1">
                  <span class="number">01</span>
                  <span class="title">课程内容</span>
                </div>
                <div class="line2">课程学习内容多为讲师旁白讲解加规范内容展示，后续抖工平台将增加更多施工现场实际案例，让培训人员更直观的掌握知识内容。</div>
                <img class="rightArrow" src="../../assets/images/right_arrow.png" alt="">
              </div>
              <div class="item">
                <div class="line1">
                  <span class="number">02</span>
                  <span class="title">课程画面</span>
                </div>
                <div class="line2">教学视频画面后续将重新制作编排，甄选抖工视频库优质视频内容，符合各项安全规范、技术措施，保证画面内容一致性及权威性。</div>
                <img class="rightArrow" src="../../assets/images/right_arrow.png" alt="">
              </div>
            </div>
            </transition>
          </div>
        </div>
        <div class="main-li main-li9">
          <div>
            <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInRight"
            leave-active-class="animated zoomOut"
            :duration="3000"
          >
            <div class="line1" v-show="transitionFlag9">
              <div class="item">
                <img class="image" src="../../assets/images/book.png" alt="">
                <div class="title">小而精，更加适合碎片化<br>移动化、离散式学习</div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">时长严格控制在 3~5分钟</div>
                </div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">完整微课结构: 导入、讲解、总结</div>
                </div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">主体形式丰富：讲授类、演示类、练习类</div>
                </div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">知识点小，信息点集中，清晰简要</div>
                </div>
              </div>
              <div class="item">
                <img class="image" src="../../assets/images/people.png" alt="">
                <div class="title">直接面向一线建筑行业从业人员<br>内容选题更加精准</div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">庞大的现场实拍素材库</div>
                </div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">精选知识点，贴合作业现场</div>
                </div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">简明扼要剖析问题，得出安全解决方案</div>
                </div>
              </div>
              <div class="item">
                <img class="image" src="../../assets/images/study.png" alt="">
                <div class="title">结果可控，真实有效</div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">边学习边考核</div>
                </div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">千人千卷，答错重新学习</div>
                </div>
                <div class="list">
                  <div class="circle"></div>
                  <div class="content">考核完毕视频留底，确保真人学习可溯源</div>
                </div>
              </div>
            </div>
            </transition>
            <transition
                name="fade"
                mode="out-in"
                enter-active-class="animated fadeIn"
                leave-active-class="animated zoomOut"
                :duration="3000"
              >
            <div class="line2" v-show="transitionFlag9f">
              截止2021年7月抖工安教目前已研发超过8500分钟，700份微课教材，行业翘首。<br>内容上严格遵循微课制作标准，融入建筑行业场景需求，三五分钟只讲一个点，确保知识点结构清晰，传达准确。<br>抖工教材团队由专业持证安全员、专业媒体团队组成，全高清实地拍摄，务必让学员朋友看得懂、听得懂、学得懂
            </div>
            </transition>
          </div>
        </div>
        <div class="main-li main-li10">
          <div>
            <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInLeft"
            leave-active-class="animated zoomOut"
            :duration="3000"
            >
            <img v-show="transitionFlag10" src="../../assets/images/curriculum.png">
            </transition>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <!-- 小于定于平板模式时 -->
    <div v-if="screenWidth<=975 && screenWidth>765">
      <aboutPad />
    </div>
    <div v-if="screenWidth<=765">
      <aboutPhone />
    </div>
    <footer class="footer">
      <div class="footer-box">
        <span class="protocol-item" @click="toProtocolPage(1)">注册协议</span>
        <span class="protocol-item" @click="toProtocolPage(2)">隐私协议</span>
        <span class="protocol-item" @click="toProtocolPage(3)">侵权投诉指引</span>
      </div>
      <div class="footer-info">
<!--        <div @click="toGovWeb">粤ICP备20000709号 | Copyright 2019-2020 佛山抖工科技有限公司</div>-->
        <div>
          <a
              target="_blank"
              href="http://beian.miit.gov.cn"
              title="抖工科技有限公司"
          >
            <i class="police"></i> 粤ICP备20000709号 | Copyright 2019-2020 佛山抖工科技有限公司
          </a>
        </div>
        <div>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060502001916"
            title="抖工科技有限公司"
          >
            <i class="police"></i> 粤公网安备 44060502001916号
          </a>
        </div>
        <div>地址：佛山市南海区桂城街道桂澜北路28号南海万达广场南1栋2509室</div>
        <div style="margin-top: 5px;color: #515a6e;font-size: 1rem;">服务热线：400-640-6898</div>
      </div>
    </footer>
  </div>
</template>

<script>
import aboutPad from "./aboutPad.vue";
import aboutPhone from "./aboutPhone.vue";
export default {
  components: {
    aboutPad,
    aboutPhone
  },
  data() {
    return {
      active: 0, // 当前激活的导航索引
      navStatus: false,
      transitionFlag1: false,
      transitionFlag2: false,
      transitionFlag3: false,
      transitionFlag4: false,
      transitionFlag5: false,
      transitionFlag6: false,
      transitionFlag7: false,
      transitionFlag8: false,
      transitionFlag9: false,
      transitionFlag10: false,
      transitionFlag1f: false,
      transitionFlag2f: false,
      transitionFlag3f: false,
      transitionFlag4f: false,
      transitionFlag5f: false,
      transitionFlag6f: false,
      transitionFlag7f: false,
      transitionFlag8f: false,
      transitionFlag9f: false,
      // transitionFlag10f: false,
      screenWidth: document.body.clientWidth,
      lineWidth: 0,
      step: 50,
    };
  },
  created() {},
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll);
    this.lineWidth = 80;
    this.transitionFlag1 = true;
    setTimeout(() => {
      this.transitionFlag1f = true;
    }, 300);
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    screenWidth(val) {
      if (!this.timer) {
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        // setTimeout(function() {
        //   // console.log(that.screenWidth);
        // }, 400);
      }
    },
    active(val) {
      if (val >= 0) {
        this.lineWidth = 0;
        setTimeout(() => {
          this.lineWidth = 80;
        }, 100);
      }
    }
  },
  destory() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    toHome() {
      this.$router.push({ name: "home" });
    },
    toVideo(){
      this.$router.push({ name: "video" });
    },
    toPlatform() {
      const URL = "http://doc.dougongapp.com:18080/en/docs/";
      window.open(URL, "_blank");
    },
    toProtocolPage(type) {
      this.common.toProtocolPage(type);
    },
    toGovWeb(){
      window.open("http://beian.miit.gov.cn", '_blank');
    },
    onScroll() {
      // 可视区域高度
      const windowHeight =
        document.documentElement.clientHeight ||
        document.body.clientHeight ||
        window.pageXOffset; //要做兼容 在模拟器能正常获取scrolltop在微信h5页面和手机的浏览器页面一直为0;
      // 获取当前文档流的 scrollTop
      const scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset; //要做兼容 在模拟器能正常获取scrolltop在微信h5页面和手机的浏览器页面一直为0
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".main-li");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      // console.log(windowHeight,offsetTopArr)
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n] - 200) {
          navIndex = n;
          if(navIndex <= 5){
            this.navStatus = false
          }else{
            this.navStatus = true
          }
        }
        if (scrollTop + windowHeight > offsetTopArr[n] + 500) {
          switch (n) {
            case 0:
              this.transitionFlag1 = true;
              setTimeout(() => {
                this.transitionFlag1f = true;
              }, 300);
              break;
            case 1:
              this.transitionFlag2 = true;
              setTimeout(() => {
                this.transitionFlag2f = true;
              }, 300);
              break;
            case 2:
              this.transitionFlag3 = true;
              setTimeout(() => {
                this.transitionFlag3f = true;
              }, 300);
              break;
            case 3:
              this.transitionFlag4 = true;
              setTimeout(() => {
                this.transitionFlag4f = true;
              }, 300);
              break;
            case 4:
              this.transitionFlag5 = true;
              setTimeout(() => {
                this.transitionFlag5f = true;
              }, 300);
              break;
            case 5:
              this.transitionFlag6 = true;
              setTimeout(() => {
                this.transitionFlag6f = true;
              }, 300);
              break;
            case 6:
              this.transitionFlag7 = true;
              setTimeout(() => {
                this.transitionFlag7f = true;
              }, 300);
              break;
            case 7:
              this.transitionFlag8 = true;
              setTimeout(() => {
                this.transitionFlag8f = true;
              }, 300);
              break;
            case 8:
              this.transitionFlag9 = true;
              setTimeout(() => {
                this.transitionFlag9f = true;
              }, 300);
              break;
            case 9:
              this.transitionFlag10 = true;
              // setTimeout(() => {
              //   this.transitionFlag10f = true;
              // }, 300);
              break;
          }
        }
      }
      // 把下标赋值给 vue 的 data
      this.active = navIndex;
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      const targetOffsetTop = document.querySelector(`.main-li${index + 1}`)
        .offsetTop;
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
      const STEP = this.step;
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp();
      } else {
        // 往下滑
        smoothDown();
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown);
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP;
          } else {
            scrollTop = targetOffsetTop;
          }
          document.body.scrollTop = scrollTop;
          document.documentElement.scrollTop = scrollTop;
          requestAnimationFrame(smoothUp);
        }
      }
    },
    switchNav(index){
      this.step = 5000
      this.scrollTo(index)
      this.step = 50
    }
  }
};
</script>

<style scoped lang="less">
.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  .head {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.875em 10% 0 10%;
    align-items: flex-start;
    height: 80px;
    background: #F2F2F2;
    z-index: 30;
    .logobox{
      width: 120px;
      // margin-left: 50px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .navbox {
      display: flex;
      color: #434343;
      font-size: 16px;
      .navitem-on {
        color: #00c9ff;
      }
      .navitem {
        cursor: pointer;
        text-align: center;
        width: 100px;
      }
    }
  }
  .nav-left {
    z-index: 20;
    width: 200px;
    // background: #F2F2F2;
    position: fixed;
    height: 100%;
    display: flex;
    align-items: center;
    left: 5%;
    .logo {
      width: 100%;
    }

    .onenav{
      width: 100%;
      position: absolute;
      top: 30px;
      left: 0;
      display: flex;
      justify-content: space-between;

      .navCancel{
        width: 76px;
        height: 53px;
        -webkit-text-stroke: 1px rgba(67, 67, 67, 1);
        text-stroke: 1px rgba(67, 67, 67, 1);
        font-size: 38px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: rgba(29,209,254,0.00);
        line-height: 53px;
      }

      .navAction{
        width: 76px;
        height: 53px;
        font-size: 38px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #1dd1fe;
        line-height: 53px;
      }
    }

    .nav {
      width: 100%;
      .nav-li-on {
        color: #00c9ff !important;
        -webkit-text-stroke: 0 !important;
        text-stroke: 0 !important;
      }
      // .nav-li-on::after {
      //   content: " ";
      //   width: 80px;
      //   height: 1px;
      //   background: #00c9ff;
      //   position: absolute;
      //   right: 15px;
      //   transition:width .5s linear;
      //   // margin-right: 72px;
      // }
      .nav-li {
        position: relative;
        .rihtLine{
          width: 0px;
          height: 2px;
          background: #00c9ff;
          position: absolute;
          right: 15%;
          top: 51%;
          transition:width .5s linear;
        }
        cursor: pointer;
        // font-size: 36px;
        font-size: 32px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0);
        -webkit-text-stroke: 1px rgba(67, 67, 67, 1);
        text-stroke: 1px rgba(67, 67, 67, 1);
        margin-bottom: 45px;
        display: flex;
        align-items: center;
      }
    }
  }
  .main-right {
    margin: 0 auto;
    background: #f2f2f2;
    width: 1200px; //内容安全距离
    // padding-top: 80px;
    // margin-top: 80px;
    .watermark{
      z-index: 1;
      position: fixed;
      width: 50%;
      bottom: -10%;
      right: -10%;
      img{
        width: 100%;
        height: auto;
      }
    }
    .main-li {
      width: 100%;
      position: relative;
      // padding: 15vh 70px 120px 70px;
      padding: 0 70px 0 70px;
      // margin-bottom: 80px;
      min-height: 750px;
      height: 100vh;
      .info-text{
          text-shadow: #f2f2f2 1px 0 0, #f2f2f2 0 1px 0, #f2f2f2 -1px 0 0, #f2f2f2 0 -1px 0;
          font-size: 14px;
      }
      .infobox{
        position: relative;
      }
      .contentbox{
        z-index: 10;
      }
      .imgbox{
        z-index: 10;
      }
    }
    .main-li1 {
      display: flex;
      align-items: center;
      .imgbox {
        // width: 580px;
        width: 464px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .contentbox{
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: -150px;
      }
      .infobox {
        width: 700px;
        .info-title {
          color: #0575e6;
          font-size: 48px;
          margin-bottom: 30px;
        }
        .info-text {
          color: #434343;
          // font-size: 18px;

        }
      }
      .fontbox {
        // position: absolute;
        // left: 38%;
        // top: 72%;
        position: absolute;
        bottom: -85%;
        width: 700px;
        left: 0;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .main-li2 {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .contentbox{

      }
      .content-imgbox{
        display: flex;
        width: 100%;
        overflow: hidden;
      }
      .imgbox1 {
        img {
          width: auto;
          height: 280px;
        }
        margin-right: 30px;
      }
      .imgbox2 {
        img {
          width: auto;
          height: 280px;
        }
      }
      .infobox {
        width: 100%;
        .info-title {
          color: #0575e6;
          font-size: 48px;
          margin-bottom: 30px;
        }
        .info-text {
          color: #434343;
          // font-size: 18px;
          margin-bottom: 40px;
        }
      }
      .fontbox {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .main-li3 {
      display: flex;
      align-items: center;
      .imgbox {
        // width: 526px;
        width: 421px;
        z-index: 1;
        img {
          width: 100%;
          height: auto;
        }
      }
      .contentbox{
        z-index: 5;
        flex: 1;
        margin-right: -100px;
      }
      .infobox {
        width: 100%;
        .info-title {
          color: #0575e6;
          font-size: 48px;
          margin-bottom: 30px;
        }
        .info-text {
          color: #434343;
          // font-size: 18px;
        }
        .info-text:not(:last-child) {
          margin-bottom: 45px;
        }
      }
      .fontbox {
        width: 900px;
        position: absolute;
        top: -90%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .main-li4 {
      display: flex;
      align-items: center;
      .imgbox {
        // width: 520px;
        width: 416px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .contentbox{
        flex: 1;
        margin-left: -150px;
      }
      .infobox {
        width: 100%;
        margin-top: 70px;
        .info-title {
          color: #0575e6;
          font-size: 48px;
          margin-bottom: 30px;
        }
        .info-text {
          color: #434343;
          // font-size: 18px;
        }
        .info-text:not(:last-child) {
          margin-bottom: 45px;
        }
      }
      .fontbox {
        top: -90%;
        position: absolute;
        right: 0;
        width: 530px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .main-li5 {
      display: flex;
      align-items: center;
      .imgbox {
        width: 387px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .contentbox{
        flex: 1;
        margin-left: -100px;
      }
      .infobox {
        // margin-bottom: 40px;
        .info-title {
          color: #0575e6;
          font-size: 48px;
          margin-bottom: 30px;
        }
        .info-text {
          color: #434343;
          // font-size: 18px;
          margin-bottom: 40px;
        }
        .info-text:not(:last-child) {
          margin-bottom: 30px;
        }
      }
      .fontbox {
        width: 85%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .main-li6 {
      // margin-bottom: 268px;
      display: flex;
      align-items: center;
      .imgbox {
        width: 416px;
        z-index: 5;
        img {
          width: 100%;
          height: auto;
        }
      }
      .contentbox{
        flex: 1;
        margin-right: -50px;
        z-index: 10;
      }
      .infobox {
        .info-title {
          color: #0575e6;
          font-size: 48px;
          margin-bottom: 30px;
        }
        .info-text {
          color: #434343;
          // font-size: 18px;
        }
        .info-text:not(:last-child) {
          margin-bottom: 30px;
        }
      }
      .fontbox {
        z-index: -1;
        width: 600px;
        position: absolute;
        top: -25%;
        left: 0;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
.footer{
    width:100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 18px;
    .footer-box{
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      .protocol-item{
        font-size:1rem;
        cursor:pointer;
        margin: 0 15px;
        width: 150px;
        text-align: center;
      }
    }
    .footer-info{
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      font-size: 12px;
      i{

        display:inline-block;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: 5px;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }
      .police{
        background-image:url('../../assets/images/guohui.png');
      }
      a{
        color: #999;
      }
    }
}

.main-li7{
  display: flex;
  align-items: center;
  justify-content: center;

  .outline{
    width: 941px;

    .list1{
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title{
        height: 45px;
        font-size: 32px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #0875e6;
        line-height: 45px;
        margin-bottom: 19px;
      }

      .content{
        height: 45px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: justify;
        color: #333333;
        line-height: 24px;
      }
    }

    .list2{
      margin-top: 20px;
      display: flex;

      .left{
        width: 168px;
        height: 428px;
        background: #d6edf3;
        border-radius: 12px;
        margin-right: 20px;

        .title{
          margin: 20px 0 0 24px;
          width: 60px;
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          color: #333333;
          line-height: 28px;
          position: relative;

          &::before{
            content: "";
            width: 6px;
            height: 17px;
            background: #1dd1fe;
            position: absolute;
            top: 50%;
            left: -24px;
            transform: translate(0,-50%);
          }
        }

        .outlineBox{
          display: inline-block;
          padding: 10px;
          background: #ffffff;
          border: 1px dashed #1dd1fe;
          border-radius: 8px;
          text-align: center;
          min-width: 66px;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          margin: 20px 0 0 23px;
        }
      }

      .right{
        width: 753px;
        height: 428px;
        background: #d6edf3;
        border-radius: 12px;

        .title{
          margin: 20px 0 0 24px;
          width: 60px;
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          color: #333333;
          line-height: 28px;
          position: relative;

          &::before{
            content: "";
            width: 6px;
            height: 17px;
            background: #1dd1fe;
            position: absolute;
            top: 50%;
            left: -24px;
            transform: translate(0,-50%);
          }
        }

        .content{
          display: flex;
          flex-wrap: wrap;
          margin: 23px 0 0 24px;

        .outlineBox{
            display: inline-block;
            padding: 10px 12px;
            background: #ffffff;
            border: 1px dashed #1dd1fe;
            border-radius: 8px;
            text-align: center;
            min-width: 66px;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            margin: 0 10px 8px 0;
          } 
        }
      }
    }
  }
}

.main-li8{
  display: flex;
  align-items: center;
  justify-content: center;
  
  .content{
    display: flex;

    .left{
      width: 620px;
      height: 557px;
      opacity: 0.91;
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.10); 
      margin-right: 23px;
      position: relative;

      .title{
        width: 504px;
        height: 40px;
        font-size: 28px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #333333;
        line-height: 40px;
        margin: 60px 0 14px 38px;
      }

      .line{
        width: 542px;
        height: 32px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 500;
        text-align: left;
        color: #333333;
        line-height: 32px;
        margin-left: 38px;
      }

      .line2{
        width: 542px;
        height: 32px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 500;
        text-align: left;
        color: #333333;
        line-height: 32px;
        margin-left: 98px;
      }

      .image{
        position: absolute;
        top: 9px;
        right: 12px;
      }
    }

    .right{

      .mb{
        margin-bottom: 20px;
      }

      .item{
        width: 410px;
        height: 268px;
        background: #d6edf3;
        border-radius: 12px;
        position: relative;

        .line1{
          display: flex;

          .number{
            width: 45px;
            height: 48px;
            font-size: 40px;
            font-family: Helvetica, Helvetica-Regular;
            font-weight: 400;
            text-align: center;
            color: #1dd1fe;
            line-height: 48px;
            margin: 39px 0 0 57px;
          }

          .title{
            width: 112px;
            height: 40px;
            font-size: 28px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: center;
            color: #333333;
            line-height: 40px;
            margin: 47px 0 0 22px;
          }
        }

        .line2{
          width: 312px;
          height: 128px;
          font-size: 18px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          line-height: 32px;
          margin: 14px 0 0 55px;
        }

        .rightArrow{
          position: absolute;
          top: 50%;
          left: -44px;
          transform: translate(0,-50%);
        }
      }

      .item2{
        width: 410px;
        height: 268px;
        background: #d6edf3;
        border-radius: 12px;
      }
    }
  }
}

.main-li9{
  display: flex;
  justify-content: center;
  align-items: center;

  .line1{
    width: 940px;
    display: flex;
    justify-content: space-between;

    .item{
      width: 300px;
      height: 424px;
      background: linear-gradient(180deg,rgba(255,255,255,0.00), #a1e1f6);
      border: 1px solid #1dd1fe;
      border-radius: 20px;

      .image{
        margin: 65px 0 0 121px;
      }

      .title{
        height: 44px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #333333;
        line-height: 22px;
        margin: 41px 0 29px 0;
      }

      .list{
        margin-left: 21px;
        position: relative;

        .circle{
          width: 6px;
          height: 6px;
          background: #1dd1fe;
          border-radius: 50%;
          display: inline-block;
          position: absolute;
          top: 12px;
          left: 0;
        }

        .content{
          width: 250px;
          height: 28px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          line-height: 28px;
          display: inline-block;
          margin-left: 16px;
        }
      }
    }
  }

  .line2{
    width: 763px;
    height: 72px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 24px;
    margin-top: 27px;
  }
}

.main-li10{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
