<template>
  <div class="aboutPad">
    <header>
      <div class="p-head">
        <div class="p-head-logo">
          <img src="../../assets/images/logo.png" alt />
        </div>
      </div>
      <div class="p-head-home">
        <Dropdown trigger="click" style="margin-left: 20px" placement="bottom-end">
        <!-- <Dropdown trigger="click" style="margin-left: 20px"> -->
          <a href="javascript:void(0)">
            <Icon type="md-menu" size="22" />
          </a>
          <DropdownMenu slot="list">
            <DropdownItem @click.native="toHome">首页</DropdownItem>
            <DropdownItem>关于我们</DropdownItem>
            <DropdownItem @click.native="toVideo">视频中心</DropdownItem>
            <DropdownItem @click.native="toPlatform">开放平台</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </header>
    <div class="p-main">
      <div class="ph-main-item p-main-item1">
        <!-- <div class="p-imgbox"><img src="../../assets/images/about_anjiao.png" alt=""></div> -->
        <transition
          name="fade"
          mode="out-in"
          enter-active-class="animated fadeInUp"
          leave-active-class="animated zoomOut"
          :duration="3000"
        >
          <div class="leftbox" v-show="phoneTransitionFlag1">
            <!-- <div class="p-title">安教</div> -->
            <!-- <div class="p-fontbox">
              <img src="../../assets/images/Safety2x.png" alt />
            </div> -->
            <div class="p-imgbox"><img src="../../assets/images/phone_anjiao.png" alt=""></div>
            <div class="p-infobox">
              <h4>在线教育 避免聚集</h4>
              <p>传统建筑三级安全教育采用集中面授式培训，存在组织难、监督难、考核难、教材制作难、资料归档难等问题。建筑工地作业人员流动性高，会议\办公空间有限，频发的集中培训活动不利于卫生防护特别是传染性疫情的控制。 抖工安教采用离散式实名在线培训模式，在满足建筑工地三级安全教育的法律法规前提下，实现作业人员安全培训全程自动化。</p>
            </div>
          </div>
        </transition>
      </div>
      <div class="ph-main-item p-main-item2">
        <transition
          name="fade"
          mode="out-in"
          enter-active-class="animated fadeInUp"
          leave-active-class="animated zoomOut"
          :duration="3000"
        >
          <div class="leftbox" v-show="phoneTransitionFlag2">
            <!-- <div class="p-title">实名</div> -->
            <!-- <div class="p-fontbox">
              <img src="../../assets/images/Real-name2x.png" alt />
            </div> -->
            <div class="p-imgbox"><img src="../../assets/images/phone_shiming.png" alt=""></div>
            <div class="p-infobox">
              <h4>自助实名 极速构建工地花名册</h4>
              <p>抖工安教实名登记采用工人自主认证方式，通过抖工安教APP即可完成身份证识别和人像识别，全程“0”输入，可以快速的构建起工地实名制花名册。相比于手工登记或者是身份证刷卡机读卡登记需要专职工作人员的作业方式简便了许多。班组长以及项目管理人员也可以实时知晓每一个工人的实名登记情况，更好的管理班组人员变动情况。</p>
            </div>
          </div>
          <!-- <div class="p-imgbox">
            <div class="imgli"><img src="../../assets/images/shiming1.png" alt=""></div>
            <div class="imgli"><img src="../../assets/images/shiming2.png" alt=""></div>
          </div>-->
        </transition>
      </div>
      <div class="ph-main-item p-main-item3">
        <transition
          name="fade"
          mode="out-in"
          enter-active-class="animated fadeInUp"
          leave-active-class="animated zoomOut"
          :duration="3000"
        >
          <div class="leftbox" v-show="phoneTransitionFlag3">
            <!-- <div class="p-title">微课</div> -->
            <!-- <div class="p-fontbox">
              <img src="../../assets/images/classical2x.png" alt />
            </div> -->
            <div class="p-imgbox"><img src="../../assets/images/phone_weike.png" alt=""></div>
            <div class="p-infobox">
              <h4>精品微课 好学好学</h4>
              <p>抖工微课中心自主研发了超过500份工地安全培训微课教材，教材根据规范编制为2-3分钟的微课教材，每一份教材均配有视频、图文、考题、语音。将安全教育的知识点进行实地场景或者情景还原拍摄，让安全知识更易被工人理解和吸收，不识字或者视力\听力有障碍工人也能完成学习。</p>
              <!-- <p>抖工教材中心，根据规范编制图文教材，针对知识点并结合当地安监要求编制考题，将视频、图文、考题三者融合程标准化教材。</p> -->
            </div>
          </div>
          <!-- <div class="p-imgbox"><img src="../../assets/images/about_weike.png" alt=""></div> -->
        </transition>
      </div>
      <div class="ph-main-item p-main-item4">
        <!-- <div class="p-imgbox"><img src="../../assets/images/about_peixun.png" alt=""></div> -->
        <transition
          name="fade"
          mode="out-in"
          enter-active-class="animated fadeInUp"
          leave-active-class="animated zoomOut"
          :duration="3000"
        >
          <div class="leftbox" v-show="phoneTransitionFlag4">
            <!-- <div class="p-title">培训</div> -->
            <!-- <div class="p-fontbox">
              <img src="../../assets/images/task2x.png" alt />
            </div> -->
            <div class="p-imgbox"><img src="../../assets/images/phone_peixun.png" alt=""></div>
            <div class="p-infobox">
              <h4>自定义培训任务 一键下发</h4>
              <p>抖工安教平台完全覆盖三级安全教育的培训管理需求，轻松组织安全培训任务，通过APP下发到每一个工人手机中，工人可以像“上微信,看抖音”一样完成安全培训学习。 通过培训监控中心企业可以了解每一个项目每一个工人被安排了什么培训任务？任务内容是什么？都分配给了谁？谁学习了多少时间？学习成果怎么样？都可以通过系统得到量化的数据结果。 </p>
              <!-- <p>抖工安教前端通过APP的形式,安装在每一个工人的手机中,直接将工人与管理后端连接,培训任务直接下发到工人手机中，工人可以像“上微信,看抖音”一样完成安全培训学习。</p> -->
            </div>
          </div>
        </transition>
      </div>
      <div class="ph-main-item p-main-item5">
        <transition
          name="fade"
          mode="out-in"
          enter-active-class="animated fadeInUp"
          leave-active-class="animated zoomOut"
          :duration="3000"
        >
          <div class="leftbox" v-show="phoneTransitionFlag5">
            <!-- <div class="p-title">考核</div> -->
            <!-- <div class="p-fontbox">
              <img src="../../assets/images/test2x.png" alt />
            </div> -->
            <div class="p-imgbox"><img src="../../assets/images/phone_kaohe.png" alt=""></div>
            <div class="p-infobox">
              <h4>千人千卷 严控安全意识</h4>
              <p>抖工安教采用边培训边做题的方式进行考核，培训内容均为微课短视频以及带语音朗读的图文材料，每一节微课均有配套的考题，视力或听力无障碍的工人均可完成培训学习和考核。</p>
              <p>在完成培训任务的过程中，每完成一节微课（一般小于3分钟）的学习，都需要进行答题，并且题目具有系统语音朗读功能。</p>
              <p>工人答对题目后，自动进入下一节微课学习，如果回答错误，则需要重新学习本微课。工人完成培训的同时也就完成了安全意识考核，考核必须是满分才可完成培训任务。</p>
            </div>
          </div>
          <!-- <div class="p-imgbox"><img src="../../assets/images/about_kaohe.png" alt=""></div> -->
        </transition>
      </div>
      <div class="ph-main-item p-main-item6">
        <!-- <div class="p-imgbox"><img src="../../assets/images/about_suoyuan.png" alt=""></div> -->
        <transition
          name="fade"
          mode="out-in"
          enter-active-class="animated fadeInUp"
          leave-active-class="animated zoomOut"
          :duration="3000"
        >
          <div class="leftbox" v-show="phoneTransitionFlag6">
            <!-- <div class="p-title">溯源</div> -->
            <!-- <div class="p-fontbox">
              <img src="../../assets/images/roots2x.png" alt />
            </div> -->
            <div class="p-imgbox"><img src="../../assets/images/phone_suoyuan.png" alt=""></div>
            <div class="p-infobox">
              <h4>扫码溯源 有备无患</h4>
              <p>抖工安教采用区块链技术，在遵循当前行业规范的前提下，构建了安全培训电子档案溯源系统，可以通过系统追溯工人的历史培训情况。有效杜绝瞒报、伪造培训记录的情况。</p>
              <p>抖工安教档案中心,可通过电子签名或扫描纸质签名资料的方式登记每一位工人的培训归档记录,形成可追溯的电子档案库。</p>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phoneTransitionFlag1: false,
      phoneTransitionFlag2: false,
      phoneTransitionFlag3: false,
      phoneTransitionFlag4: false,
      phoneTransitionFlag5: false,
      phoneTransitionFlag6: false
    };
  },
  mounted() {
    let _this = this;
    // 展示元素的高和距离顶部距离
    // let revealOffsetTop = document.getElementById("reveal").offsetTop;
    // let revealOffsetHeight = document.getElementById("reveal").offsetHeight;
    // let testOffsetTop = document.getElementById("test").offsetTop;
    // let testOffsetHeight = document.getElementById("test").offsetHeight;
    let navContents = document.querySelectorAll(".ph-main-item");
    // 所有锚点元素的 offsetTop
    let offsetTopArr = [];
    navContents.forEach(item => {
      offsetTopArr.push(item.offsetTop);
    });
    // 可视区域高度
    let windowHeight =
      document.documentElement.clientHeight ||
      document.body.clientHeight ||
      window.pageXOffset; //要做兼容 在模拟器能正常获取scrolltop在微信h5页面和手机的浏览器页面一直为0;
    // console.log(windowHeight, offsetTopArr);
    for (let n = 0; n < offsetTopArr.length; n++) {
      if (windowHeight > offsetTopArr[n]) {
        switch (n) {
          case 0:
            this.phoneTransitionFlag1 = true;
            break;
          case 1:
            this.phoneTransitionFlag2 = true;
            break;
          case 2:
            this.phoneTransitionFlag3 = true;
            break;
          case 3:
            this.phoneTransitionFlag4 = true;
            break;
          case 4:
            this.phoneTransitionFlag5 = true;
            break;
          case 5:
            this.phoneTransitionFlag6 = true;
            break;
        }
      }
    }
    // window.addEventListener('scroll',function(){

    // })
    window.addEventListener("scroll", this.onScrollPhone);
    // this.phoneTransitionFlag1 = true;
  },
  methods: {
    toHome(){
      this.$router.push({name: 'home'});
    },
    toVideo(){
      this.$router.push({name: 'video'});
    },
    toPlatform(){
      const URL = 'http://doc.dougongapp.com:18080/en/docs/'
      window.open(URL, '_blank')
    },
    onScrollPhone() {
      let scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset; //要做兼容 在模拟器能正常获取scrolltop在微信h5页面和手机的浏览器页面一直为0
      let windowHeight =
        document.documentElement.clientHeight ||
        document.body.clientHeight ||
        window.pageXOffset; //要做兼容 在模拟器能正常获取scrolltop在微信h5页面和手机的浏览器页面一直为0;
      let navContents = document.querySelectorAll(".ph-main-item");
      // 所有锚点元素的 offsetTop
      let offsetTopArr = [];
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop);
      });
      for (let n = 0; n < offsetTopArr.length; n++) {
        if (scrollTop + windowHeight > offsetTopArr[n] + 100) {
          switch (n) {
            case 0:
              this.phoneTransitionFlag1 = true;
              break;
            case 1:
              this.phoneTransitionFlag2 = true;
              break;
            case 2:
              this.phoneTransitionFlag3 = true;
              break;
            case 3:
              this.phoneTransitionFlag4 = true;
              break;
            case 4:
              this.phoneTransitionFlag5 = true;
              break;
            case 5:
              this.phoneTransitionFlag6 = true;
              break;
          }
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.aboutPad {
  margin-bottom: 220px;
  h4 {
    font-size: 20px;
    color: #0575e6;
    margin-bottom: 0.8em;
    font-weight: bold;
    text-align: center;
  }
  p {
    font-size: 14px;
    color: #434343;
  }
  p:not(:last-child) {
    margin-bottom: 10px;
  }
  header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    height: 64px;
    .p-head {
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .p-head-logo {
        width: 200px;
        text-align: center;
      }
    }
    .p-head-home {
      position: absolute;
      right: 3vw;
      top: 22px;
      color: #333;
    }
  }
  .p-main {
    width: 100%;
    .ph-main-item {
      display: flex;
      padding: 15px 30px;
      position: relative;
      min-height: 200px;
      .p-imgbox{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3em 0;
        img{
          width: 40%;
          height: auto;
        }
      }
      .p-fontbox {
        margin-bottom: -35px;
      }
      .p-title {
        width: 100%;
        font-size: 24px;
        // font-weight: bold;
        display: flex;
        justify-content: center;
        padding: 16px 0;
        color: #1f2f3d;
        position: relative;
        margin-bottom: 40px;
      }
      .p-title::after {
        content: "";
        width: 70px;
        height: 1px;
        position: absolute;
        background: #0575e6;
        top: 50%;
        left: 70%;
      }
      .p-title::before {
        content: "";
        width: 70px;
        height: 1px;
        position: absolute;
        background: #0575e6;
        top: 50%;
        right: 70%;
      }
    }
    .ph-main-item:not(:last-child) {
      margin-bottom: 80px;
    }
    .ph-main-item:last-child {
      margin-bottom: 68px;
    }
    .p-main-item1 {
      align-items: center;
      .leftbox {
        flex: 1;
        // margin-left: -60px;
      }
      .p-infobox {
        flex: 1;
        flex-direction: column;
        // margin-top: 40px;
        // margin-bottom: 20px;
      }
      .p-fontbox {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .p-main-item2 {
      flex-direction: column;
      .leftbox {
        height: 100%;
        // margin-bottom: 5px;
      }
      .p-infobox {
        width: 100%;
        // margin-bottom: 20px;
      }
      .p-fontbox {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .p-main-item3 {
      align-items: center;
      .leftbox {
        flex: 1;
        // margin-right: -60px;
        z-index: 1;
      }
      .p-infobox {
        flex: 1;
      }
      .p-fontbox {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .p-main-item4 {
      align-items: center;
      .leftbox {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1;
        // margin-left: -60px;
      }
      .p-infobox {
        flex: 1;
      }
      .p-fontbox {
        width: 100%;
        // margin-bottom: 10px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .p-main-item5 {
      align-items: center;
      .leftbox {
        flex: 1;
        // margin-right: -60px;
        z-index: 1;
      }
      .p-infobox {
        flex: 1;
        margin-bottom: 10px;
      }
      .p-fontbox {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .p-main-item6 {
      align-items: center;
      .leftbox {
        flex: 1;
        // margin-left: -60px;
      }
      .p-infobox {
        flex: 1;
      }
      .p-fontbox {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>